import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                “I just came here with my 1.5 yo daughter and she absolutely loved it. Wow such a cute place lots and lots of toys and different play areas and a cute little relaxation room with books. 10/10 recommend. ”
                            </p>
                            <p className="overview">Julia/ Kernersville</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We brought our four year old and 14 month old in today and had an absolute BLAST! Our oldest is autistic and our youngest has Down syndrome, so it can be hard to find things to do that mesh both of their needs and abilities, but this place is it! Both kids had an amazing time and I loved that I didn’t feel like I needed to run after my oldest and over-explain why she was doing something, constantly hover over the baby, or apologize for sensory overload moments. The owner was such a kind gentleman and I can tell he is passionate about integrating kids of all abilities. We will definitely be back (many times!).”
                            </p>
                            <p className="overview">HH/ Greensboro</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “This is one amazing place for any kid to play! My ASD kid is very comfortable here. They never get enough play time even after hours of playing!!! Thank you for the loving environment!!!"
                            </p>
                            <p className="overview">
                                Stephanie/ Winston Salem
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Amazing place, I hosted my sons second birthday party here and he had the best time ever. The little toddlers had fun and even the big kids as old as 12 had a blast. Everything was very clean and so many different toys for the toddlers to play with. The owner was very hands on and always there to help if needed."
                            </p>
                            <p className="overview">
                                SK/ High Point
                            </p>
                        </div>

                      <div className="item carousel-item">
                            <p className="testimonial">
                                “Words cannot express how much we love and appreciate We Rock The Spectrum Triad. This gym is filled with smiles, love, laughter, support, inclusion, and inspires confidence in kids. My son with Sensory Processing Disorder absolutely thrives here. Swings, a zip-line, trampoline, crash pads, calm room, toddler area-- it's perfect! My 12 month old also loves the toddler area! We had a birthday party here and all of the kids said it was the "best party ever!". We're also in Summer Camp and it has been such a positive experience. Camp has helped my son grow so much and the amazing camp staff has gone above and beyond. This gym has changed our lives for the better, and we're so thankful. Highly recommend for ALL kids!"
                            </p>
                            <p className="overview">
                                Becky/ Triad
                            </p>
                        </div> 
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
